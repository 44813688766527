import React from 'react'
import Navbar from '../../components/Commons/Navbar'
import Footer from '../../components/Layouts/Footer'

const Terms = () => {
  return (
    <div className="home">
      <Navbar />
      <div className="page_area clearfix" id="terminos">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-md-center">
            <div className="col-12 col-md-6">
              <div className="page-heading text-uppercase">
                <h2>TÉRMINOS Y CONDICIONES</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container py-5">
          <div className="row justify-content-md-center">
            <div className="col-12 col-lg-10 text-muted">
              <p className="f14" align="justify">
                Estos términos y condiciones generales (los "Términos y Condiciones” o “TyC”,
                indistintamente) informan y regulan las condiciones de uso del Programa Wong Prime a
                los Usuarios suscritos a mismo (los “Usuarios”). Cualquier persona que desee acceder
                y/o usar los servicios del Programa Wong Prime podrá hacerlo respetando estos
                Términos y Condiciones, junto con todas las demás políticas y condiciones
                aplicables.
              </p>
              <p className="f14 mb-5" align="justify">
                Estos TyC son de carácter general. Los Términos y Condiciones aplicables a los
                beneficios otorgados en cada solicitud de compra o realización de compra en lo
                específico son los informados al “Usuario”, quien deberá revisar y leer
                detenidamente las condiciones indicadas en cada beneficio antes de generar su
                solicitud de compra.
              </p>
              <p className="f14 mb-5" align="justify">
                Estos Términos y Condiciones generales tienen carácter obligatorio y vinculante.
              </p>

              <h3 className="f21">DEFINICIONES</h3>
              <ul className="f14 font-weight-light mb-5" align="justify" type="A">
                <li className="mb-2">
                  <b>“Programa Wong Prime”:</b> Wong Prime es un Programa de beneficios exclusivos.
                  El objetivo del programa es que nuestros usuarios inscritos obtengan una mejor
                  experiencia de compra y beneficios adicionales tanto en el sitio web como en el
                  aplicativo de supermercados Wong. Esto se materializa mediante la entrega de
                  diferentes beneficios, además de la comunicación de oportunidades y ofertas, según
                  lo informe Wong Prime. El Programa estará disponible en el sitio web www.wong.pe y
                  en la App Wong para que los clientes interesados puedan adquirir la suscripción.
                </li>
                <li className="mb-2">
                  <b>“Usuarios”:</b> Aquellas personas naturales que se suscribieron al Programa
                  Wong Prime y que fueron previamente aprobadas según establecen estos Términos y
                  Condiciones.
                </li>
                <li className="mb-2">
                  <b>“Web Wong”:</b>Se refiere a www.wong.pe
                </li>
                <li className="mb-2">
                  <b>“App Wong”:</b> Aplicativo disponible para dispositivos móviles de la marca
                  Wong
                </li>
              </ul>
              <h4>1. CONDICIONES GENERALES DEL PROGRAMA WONG PRIME</h4>
              <p className="f14" align="justify">
                Wong Prime es un programa de beneficios para el Usuario, organizado por Cencosud
                Retail Perú S.A. (en adelante, Cencosud), RUC 20109072177, domiciliado para estos
                efectos en Calle Augusto Angulo 130, distrito de Miraflores, provincia y
                departamento de Lima, a través del cual se ofrece un servicio que otorga beneficios
                complementarios a aquellos aplicados para las compras de productos en las
                plataformas digitales Web Wong y App Wong. La entrega de los beneficios está sujeta
                a restricciones que serán debidamente informadas.
              </p>
              <p className="f14" align="justify">
                El servicio del Programa Wong Prime se encuentra referido a: i) el otorgamiento de
                beneficios en las compras realizadas por los Usuarios en la App Wong y la Web Wong,
                ii) brindar información sobre los alcances de estos beneficios del Programa Wong
                Prime en la Web Wong o en la página del Programa Wong Prime, y, iii) el registro de
                usuarios. El costo de la suscripción se encuentra publicado en el sitio web:
                www.wongprime.pe. El precio puede variar, pero se respetará durante el periodo de
                vigencia de cada suscripción. La variación del precio será informada oportunamente y
                antes de la finalización del periodo de vigencia de la suscripción, a fin de que el
                Usuario puede optar por renovar la suscripción o por la cancelación del servicio si
                así lo considerase pertinente.
              </p>
              <p className="f14" align="justify">
                En el caso de los beneficios indicados en el Programa Wong Prime que no se
                encuentren referidos a productos o servicios de Cencosud, sino de otros socios
                comerciales, el cumplimiento de estos beneficios será ejecutado por los referidos
                socios comerciales respetando las disposiciones del Código de Protección y Defensa
                del Consumidor y bajo los términos indicados en la página del Programa Wong Prime.
              </p>
              <p className="f14" align="justify">
                La lista enunciativa, mas no limitativa, de los beneficios del Programa Wong Prime
                se encuentra establecida en el Anexo A de los TyC. La modificación de los beneficios
                como consecuencia de la modificación de los TyC se realiza conforme a lo indicado en
                la cláusula 12.
              </p>
              <h4>2. USUARIOS DEL PROGRAMA</h4>
              <p className="f14" align="justify">
                Podrán participar del programa todos aquellos que cumplan con los siguientes
                requisitos:
              </p>
              <ul className="f14 font-weight-light mb-5" align="justify" type="A">
                <li className="mb-2">
                  Ser persona natural, mayor de 18 años, debidamente identificada. El registro en
                  Wong Prime deberá ser realizado únicamente desde territorio peruano. El Programa
                  sólo está disponible para personas con capacidad legal para contratar. No podrán
                  utilizarlo las personas menores de 18 años de edad, ni los Usuarios cuyas Cuentas
                  de usuario hayan sido suspendidas temporalmente o inhabilitados deﬁnitivamente
                  conforme a los supuestos recogidos a lo largo de los TyC.
                </li>
                <li className="mb-2">
                  Haber cumplido con el proceso de inscripción del programa. El Usuario debe
                  ingresar a la página web: www.wongprime.pe y suscribirse al programa.
                </li>
                <li className="mb-2">
                  Una vez completado el formulario de inscripción, los usuarios deberán registrar
                  una tarjeta de crédito válida y realizar los protocolos de autentificación.
                  Cencosud se reserva el derecho de aceptar o rechazar la solicitud de suscripción
                  atendiendo a las situaciones indicadas en los TyC, lo cual será informado vía
                  correo electrónico en un plazo máximo de 24 horas desde la fecha de solicitud de
                  suscripción. En el supuesto que se rechace la solicitud de suscripción se
                  remitirán las razones por las cuales se ha adoptado esta decisión. En el supuesto
                  en el que la solicitud sea aceptada, el cliente quedará activo en la plataforma de
                  forma gratuita en el marco de una promoción de lanzamiento que se denomina
                  “Periodo gratuito”. Cabe indicar que los protocolos de autentificación de las
                  tarjetas de crédito efectuados por las instituciones bancarias suelen requerir de
                  una retención mínima de aproximadamente S/1, la cual puede variar según cada
                  tarjeta y/o institución bancaria. En tal sentido, los usuarios declaran conocer y
                  aceptar este procedimiento bancario.
                </li>
              </ul>

              <h3 className="f21">3. CONDICIONES DE LA SUSCRIPCIÓN</h3>
              <p className="f14" align="justify">
                El Periodo gratuito se extiende por un plazo de{' '}
                {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                  <>
                    <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                    {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                  </>
                ) : (
                  `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                )}{' '}
                días calendario desde la fecha de suscripción, contabilizada desde el día posterior
                en el que realizó y aceptó la suscripción por parte de Cencosud. Luego de esa fecha,
                Cencosud realizará un cobro por el periodo de seis meses de membresía, de acuerdo
                con los valores vigentes estipulados en el sitio web www.wongprime.pe. Si el cliente
                no desea realizar el pago, deberá cancelar su suscripción en cualquier momento hasta
                antes de terminar el plazo señalado para el “Periodo gratuito”, hasta las 23:59
                horas. Este periodo gratuito únicamente podrá ser utilizado en una sola oportunidad
                por el usuario, independientemente del número de días que hizo uso de él, o del
                número de oportunidades en que se inscribió en el Programa.
              </p>
              <p className="f14" align="justify">
                Para utilizar los beneficios ofrecidos en el Programa Wong Prime, el Usuario debe
                estar inscrito en los registros de la Web Wong, App Wong y las plataformas de los
                otros socios comerciales que otorguen beneficios, y el correo electrónico y datos de
                identificación del Usuario deben coincidir con aquellos registrados en sus
                respectivos sitios web. El Usuario deberá revisar el correcto registro de sus datos
                de usuario en la Web Wong, App Wong y las plataformas de los otros socios
                comerciales que otorguen beneficios, a fin de poder hacer efectivos los beneficios
                del Programa Wong Prime.
              </p>
              <p className="f14" align="justify">
                Mediante la aceptación de estos Términos y Condiciones, el Usuario acepta y autoriza
                en forma expresa que la membresía del Programa Wong Prime se renovará
                automáticamente una vez finalizado el “período gratuito”, por períodos de 6 meses,
                efectuándose cada cargo respectivo en la tarjeta registrada, en un plazo de{' '}
                {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                  <>
                    <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                    {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                  </>
                ) : (
                  `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                )}{' '}
                días a contar desde el término del “período de prueba gratuito” o, posteriormente a
                ello, desde el término de cada período de 6 meses de suscripción. En caso de que no
                se pueda realizar el cobro en la tarjeta registrada por el Usuario, se procederá a
                solicitar el reemplazo de ésta, vía correo electrónico y mediante mensajes en su
                portal de usuario Prime. Desde el momento que se envía el correo de aviso, los
                beneficios del usuario serán bloqueados temporalmente hasta la reactivación. Para la
                reactivación se debe reemplazar la tarjeta por un medio de pago válido, mediante el
                inicio de sesión a la cuenta Usuario. El cobro se hará en las próximas 24 horas,
                desde el reemplazo.
              </p>
              <p className="f14" align="justify">
                En caso de que el cliente no realice un reemplazo de la tarjeta en un plazo de 24
                horas a partir de la fecha y hora del envío del correo electrónico respectivo, la
                membresía no se renovará.
              </p>
              <p className="f14" align="justify">
                Cencosud enviará al Usuario un correo electrónico al email registrado en el programa
                7 días antes del término del “período de prueba gratuito” y de cada período de
                suscripción, informando 1) la fecha de término del “período de prueba gratuito” o
                del respectivo período de suscripción, 2) la renovación automática de la
                suscripción, 3) los mecanismos para cancelar la suscripción y, por ende, la
                renovación automática, 4) la fecha en que se efectuará el cargo en la tarjeta
                registrada, correspondiente al siguiente período de suscripción si el Usuario no
                hubiera cancelado la suscripción a más tardar el día de término del “período de
                prueba gratuito” o el período de suscripción vigente, y, 5) en caso exista un
                incremento en el monto de la suscripción este será debidamente informado.
              </p>
              <p className="f14" align="justify">
                El usuario puede cancelar la suscripción al Programa Wong Prime en cualquier momento
                comunicándose a través del Call Center +511 6138888 o por el sitio web:
                www.wongprime.pe. En un plazo máximo de 48 horas se habrá generado la cancelación de
                la suscripción.
              </p>
              <p className="f14" align="justify">
                Luego del proceso de suscripción y aprobación del usuario, Cencosud se reserva un
                plazo de hasta 24 horas para activar los beneficios, los cuales no serán
                contabilizados dentro del “Periodo Gratuito”. Para lo anterior, el Usuario deberá
                proporcionar datos válidos, exactos, precisos y verdaderos al completar el
                formulario de registro. La información personal que el Usuario pone a disposición
                debe mantenerse actualizada. Cencosud podrá corroborar Datos Personales y suspender,
                temporal o deﬁnitivamente, a aquellos Usuarios cuyos datos no hayan podido ser
                conﬁrmados y/o que resulten no ser verdaderos. El Usuario accederá a su cuenta
                personal ("Cuenta") mediante el ingreso de un correo electrónico asociado al
                Programa, y de una clave de seguridad personal que debe elegir y definir ("Clave de
                Seguridad"), a través de la Web Wong o del App Wong.
              </p>
              <p className="f14" align="justify">
                El Usuario se obliga a mantener la conﬁdencialidad de su Clave de Seguridad. La
                Cuenta es personal, única e intransferible. El Usuario será responsable por todas
                las operaciones efectuadas en su Cuenta. El Usuario se compromete a notiﬁcar a
                Cencosud en forma inmediata a través de los canales de contacto o comunicación que
                se mantendrán informados en www.wong.pe, sobre cualquier uso no autorizado de su
                Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que
                está prohibida la venta, cesión o transferencia de la Cuenta bajo ningún título.
              </p>
              <h3 className="f21">
                4. COMPRA DE PRODUCTOS, BIENES, SERVICIOS, OFERTAS Y/O PROMOCIONES
              </h3>
              <p className="f14" align="justify">
                Cencosud y sus socios comerciales son responsables de respetar las garantías legales
                establecidas por la Ley 29571, Código de Protección y Defensa del Consumidor por la
                compra de productos, bienes, servicios y/u ofertas promocionales, en los ámbitos de
                responsabilidad que alcanzan a cada uno de ellos, en sus diferentes calidades de
                plataforma y proveedores, respectivamente.
              </p>
              <p className="f14" align="justify">
                Se recomienda que el Usuario mantenga sus datos actualizados para que la experiencia
                de compra sea óptima y segura. Si tiene alguna inquietud puede contactarse a nuestro
                Call Center al: +511 6138888 o consultar a través del formulario de preguntas y
                reclamos en el portal de cliente del Programa Wong Prime del sitio web:{' '}
                <a href="https://www.wongprime.pe">www.wongprime.pe</a>.
              </p>
              <h3 className="f21">5. VIGENCIA DEL PROGRAMA WONG PRIME Y TERMINACIÓN ANTICIPADA</h3>
              <p className="f14" align="justify">
                La vigencia del Programa Wong Prime será indefinida. Cencosud se reserva el derecho
                a terminar anticipadamente el Programa, lo que será informado oportuna y debidamente
                a los Usuarios. Si Cencosud cancela la suscripción por término anticipado del
                programa, automáticamente se procesará una devolución a favor del Usuario por el
                monto de dinero equivalente al proporcional que falte para el término del período de
                suscripción.
              </p>
              <p className="f14" align="justify">
                En el caso que Cencosud cancele la suscripción por el uso indebido del programa, o
                por entrega por parte del Cliente de información errada o no efectiva, o por
                incumplimiento de cualquiera de los deberes que se establecen en estos Términos y
                Condiciones, y/o por cualquier acción u omisión que implique algún tipo de fraude en
                contra de Cencosud, su matriz y/o sus filiales o relacionadas, tales como
                falsificación, suplantación de personas o identidad, adulteración de información,
                etc., entonces se gestionará la devolución por el monto de dinero equivalente al
                proporcional que falte para el término del período de suscripción y quedará impedido
                de reincribirse en la plataforma.
              </p>
              <h3 className="f21">6. RENUNCIA AL PROGRAMA</h3>
              <p className="f14" align="justify">
                En cualquier momento el usuario podrá renunciar al Programa Wong Prime, esta es una
                acción personalísima que deberá informar a Cencosud llamando al call center +511
                6138888 para ser orientado por un asesor o realizarla a través del portal de usuario
                Wong Prime, sección mi cuenta.
              </p>
              <p className="f14" align="justify">
                Si la renuncia al Programa Wong Prime es realizada durante los primeros 10 días
                desde el pago o renovación de la suscripción, y el usuario no utilizó alguno de los
                beneficios ofrecidos por el programa durante dicho período de 10 días, entonces se
                devolverá el 100% del costo de la membresía. La renuncia en cualquier otro momento
                durante la vigencia de la membresía o habiendo utilizado alguno de los beneficios de
                la membresía no dará derecho a devolución o restitución del dinero; sin embargo, los
                beneficios seguirán vigentes hasta el término del período de suscripción respectivo.
              </p>
              <p className="f14" align="justify">
                En el caso de un usuario que haya cancelado su suscripción o renunciado al Programa
                Wong Prime y luego desee reingresar, no tendrá “periodo gratuito”, conforme a lo
                señalado en el punto 2 de estos TyC, y el cobro a la tarjeta se realizará en el
                momento del reingreso.
              </p>
              <h3 className="f21">7. DATOS PERSONALES DE LOS USUARIOS</h3>
              <p className="f14" align="justify">
                Para efectuar solicitudes de compra u otras actividades en Wong Prime, los Usuarios
                deberán informar y/o proporcionar los datos de carácter personal necesarios para que
                se pueda verificar, confirmar o rechazar su solicitud, despachar y entregar los
                productos, en caso de proceder. Por lo anterior, hacemos hincapié e informamos que
                cuando el Usuario envía una solicitud de compra o de pedido, presta su
                consentimiento inequívoco para que Wong Prime pueda compartir los Datos Personales
                relevantes para la gestión de las solicitudes.
              </p>
              <p className="f14" align="justify">
                La información personal se procesa y almacena en servidores o medios magnéticos que
                mantienen altos estándares de seguridad y protección tanto física como tecnológica.
                Para mayor información sobre la privacidad de los Datos Personales y los casos en
                los que será revelada la información personal, se pueden consultar nuestras
                Políticas de Privacidad y Uso de Datos Personales aplicables al Programa Wong Prime
                en 
                <a
                  href="https://www.wong.pe/institucional/politicas-privacidad-seguridad"
                  target="_blank"
                  rel="noreferrer">
                  https://www.wong.pe/institucional/politicas-privacidad-seguridad{' '}
                </a>
                .
              </p>
              <h3 className="f21">8. OBLIGACIONES DE LOS USUARIOS</h3>
              <p className="f14" align="justify">
                Antes de solicitar la compra o canje de un producto o beneficio, el Usuario debe
                leer y revisar cuidadosa, detallada y responsablemente la información publicada
                sobre la identificación del bien o producto, los instructivos de uso, los términos
                de las garantías, su precio, condiciones de contratación y otras características
                relevantes. El Usuario debe tener muy presente que, una vez enviada la solicitud de
                compra, él y la Marca Participante quedarán obligados por las condiciones de venta
                incluidas en la descripción del artículo.
              </p>

              <h3 className="f21">9. DERECHOS DE LOS USUARIOS EN WONG PRIME</h3>
              <p className="f14" align="justify">
                Cencosud y/o sus socios comerciales son responsables de gestionar las peticiones de
                los Usuarios derivadas de las solicitudes de beneficios del Programa Wong Prime.
              </p>
              <h3 className="f21">10. SANCIONES. SUSPENSIÓN DE OPERACIONES</h3>
              <p className="f14" align="justify">
                Sin perjuicio de otras medidas, Wong Prime podrá advertir, suspender en forma
                temporal o inhabilitar deﬁnitivamente la Cuenta de un Usuario si el titular de
                aquella incurriera en conductas contrarias a la ley, la moral, el orden público y/o
                las buenas costumbres; así como conductas contrarias a los términos y condiciones y
                políticas de Web Wong, App Wong y de nuestros socios comerciales.<br></br>
                Términos y condiciones de la Web Wong:{' '}
                <a
                  href="https://www.wong.pe/institucional/terminos-y-condiciones"
                  target="_blank"
                  rel="noreferrer">
                  https://www.wong.pe/institucional/terminos-y-condiciones
                </a>
              </p>
              <h3 className="f21">11. PROPIEDAD INTELECTUAL</h3>
              <p className="f14" align="justify">
                Todo contenido relativo a los servicios de Wong Prime como así también los
                programas, bases de datos, redes, archivos, entre otros, que permiten al Usuario
                acceder y usar su Cuenta, son de propiedad de Cencosud y están protegidas por las
                leyes y los tratados internacionales de propiedad intelectual, en particular en
                materia de marcas, derechos de autor y secretos empresariales e industriales. El uso
                indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos,
                salvo autorización expresa y por escrito de Cencosud.
              </p>
              <h3 className="f21">12. MODIFICACIONES DE LOS TÉRMINOS Y CONDICIONES</h3>
              <p className="f14" align="justify">
                Wong Prime podrá modiﬁcar los Términos y Condiciones en cualquier momento informando
                en el sitio web: <a href="www.wongprime.pe">www.wongprime.pe </a>
                los términos modiﬁcados, los que, en todo caso, no afectarán las condiciones del
                Programa del Usuario durante su vigencia. Los términos y Condiciones, y sus
                modificaciones serán comunicados en la página web y vía correo electrónico a lo
                menos 10 (diez) días antes de su entrada en vigencia.
              </p>
              <p className="f14" align="justify">
                En caso de que el Usuario de Wong Prime no desee continuar con el Programa deberá
                realizarlo de acuerdo al procedimiento establecido en el numeral 6 ”Renuncia al
                programa“.
              </p>
              <h3 className="f21">13. TÉRMINOS Y CONDICIONES DEL PAGO</h3>
              <p className="f14" align="justify">
                Los productos y servicios ofrecidos en la Web Wong o el App Wong, salvo que se
                señale una forma diferente para casos particulares u ofertas de determinados bienes
                o servicios, sólo pueden ser pagados de acuerdo con los medios de pago aceptados en
                estas plataformas.
              </p>

              <p className="f14" align="justify">
                Los productos y servicios ofrecidos mencionados en el párrafo anterior, salvo que se
                señale una forma diferente para casos particulares u ofertas de determinados bienes
                o servicios, sólo pueden ser pagados con Tarjetas de Débito o Crédito habilitadas.
              </p>

              <p className="f14" align="justify">
                Cargos en Tarjeta de Débito o Crédito: Una vez registrada una tarjeta se habilitará
                el periodo gratuito para el Usuario, el cual tendrá vigencia de{' '}
                {process.env.REACT_APP_IS_NEW_CAMPAIGN === 'true' ? (
                  <>
                    <del>{process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}</del>{' '}
                    {process.env.REACT_APP_FUTURE_NUMBER_FREE_DAYS}
                  </>
                ) : (
                  `${process.env.REACT_APP_CURRENT_NUMBER_FREE_DAYS}`
                )}{' '}
                días corridos posterior a la suscripción. Si el Usuario no cancela su suscripción al
                Programa antes de esa fecha, se generará un movimiento o cargo en su tarjeta,
                correspondiente al primer período o, luego de ello, al periodo de renovación, por el
                monto informado en la página web <a href="www.wongprime.pe">www.wongprime.pe</a>.
              </p>

              <h3 className="f21">14. PROMOCIONES</h3>
              <p className="f14" align="justify">
                El programa Wong Prime, a través de Cencosud y/o sus socios comerciales, podrán
                ofrecer a sus Usuarios ofertas, promociones, beneficios, concursos por medio de
                cupones promocionales u otros medios, para los cuales el Usuario deberá aceptar
                adicionalmente las bases de promoción o similar del oferente de la promoción
                respectiva.
              </p>
              <p className="f14" align="justify">
                Todas las bases promocionales de concurso, o similares, estarán debidamente
                publicadas en la Web Wong y/o el App Wong y por el oferente de la Promoción.
              </p>

              <h3 className="f21">15. CONSULTAS, SUGERENCIAS Y RECLAMOS</h3>
              <p className="f14" align="justify">
                WONG valora la opinión de sus clientes. Por ello, se ha habilitado una sección de{' '}
                <a href="https://www.wong.pe/consultas-y-sugerencias">Consultas y Sugerencias</a>,
                que facilitará la comunicación de dudas o comentarios que los usuarios pudieran
                tener.
              </p>
              <p className="f14" align="justify">
                Los clientes podrán comunicarse a nuestra central telefónica al 613-8888
                contactándose al área de Servicio al Cliente.
              </p>
              <p className="f14" align="justify">
                Toda queja o reclamo, relacionada con actos o contratos ejecutados o celebrados a
                través de esta web, deberá ingresarse en el{' '}
                <a target="__blank" href={'https://librorec.wong.pe/frmLRRegistro.aspx'}>
                  Libro de Reclamaciones Virtual{' '}
                </a>
                ”.
              </p>

              <h3 className="f21">16. DOMICILIO PARA RECIBIR NOTIFICACIONES.</h3>
              <p className="f14" align="justify">
                Cencosud Retail Perú S.A. R.U.C: 20109072177. Domicilio: Calle Augusto Angulo 130,
                Miraflores – Lima.
              </p>
              <p className="f14" align="justify">
                El Usuario autoriza el correo electrónico registrado en la plataforma Wong Prime
                como medio de comunicación válido a efectos de remitir toda la información y avisos
                correspondientes a los presentes Términos y Condiciones y el Programa Wong Prime.
              </p>
              <h3 className="f21">Anexo A</h3>
              <p className="f14" align="justify">
                Los beneficios que serán otorgados a los Usuarios por Cencosud y/o sus socios
                comerciales en el Programa Wong Prime son los siguientes:
              </p>
              <ul className="f14 font-weight-light mb-5" align="justify" type="A">
                <li className="mb-2">
                  Despacho a costo S/ 0 en compras de supermercado desde S/ 79,90: El beneficio de
                  despachos a costo S/ 0 será otorgado por Cencosud y podrá ser aplicado en las
                  compras realizadas en Wong.pe y App Wong de productos de supermercado (abarrotes y
                  perecibles), excluyéndose productos de electrodomésticos, tecnología, colchones,
                  productos para bebés, juguetería, vestuarios, y muebles. Para utilizar este
                  beneficio se considerará como monto mínimo en las compras el valor de S/ 79,90.
                  Las zonas y áreas de despacho serán las consideradas y disponibles según lo
                  indicado en la Web Wong y el App Wong.
                </li>
                <li className="mb-2">
                  Despacho a costo S/ 0 en productos de electrodomésticos, tecnología, colchones y
                  muebles: El beneficio de despacho a costo S/ 0 será otorgado por Cencosud y podrá
                  ser aplicado en las compras realizadas por Wong.pe y App Wong en productos de
                  electrodomésticos, tecnología, colchones y muebles como máximo hasta dos veces por
                  mes . Para acceder a este beneficio se considerará como monto mínimo S/ 299,00 en
                  las categorías mencionadas . Las zonas y áreas de despacho serán las consideradas
                  y disponibles según lo indicado en la Web Wong y el App Wong.
                </li>
                <li className="mb-2">
                  Ofertas exclusivas a Usuarios de Wong Prime: Sin perjuicio de lo anterior, Wong
                  Prime podrá realizar ofertas, promociones y/o beneficios, cuyas condiciones serán
                  informadas oportunamente por la vía que Wong Prime estime conveniente.
                </li>
                <li className="mb-2">
                  Doble Puntos Bonus en sitio web y Aplicativo móvil: El beneficio será otorgado por
                  el socio comercial Loyalty Perú S.A.C. y consiste en duplicar el bono de puntos
                  por cada compra que el cliente suscrito a Wong Prime realice en Web Wong o App
                  Wong. Se tomará como referencia para la aplicación de este beneficio lo
                  establecido en los Términos y Condiciones del programa Puntos Bonus.<br></br>
                  La acumulación de puntos será incorporada en la cuenta de Puntos Bonus del cliente
                  suscrito a Wong Prime sujeto a los plazos estipulados en los términos y
                  condiciones del programa de Puntos Bonus. Para esto, el cliente debe haber
                  realizado la compra con el mismo Usuario con el que está inscrito en Wong Prime,
                  en el respectivo sitio web para obtener el bono. <br></br>
                  En caso el cliente no se haya registrado al programa de Puntos Bonus, se
                  considerarán los puntos acumulados por sus compras realizadas antes de su
                  suscripción, los mismos que le serán adjudicados al Usuario al momento de
                  suscribirse a dicho programa.<br></br>
                  En caso de devoluciones de compras que hayan originado la acreditación del bono de
                  puntos, esto será descontado de la cuenta del Cliente.<br></br>
                  El programa de Puntos Bonus es desarrollado por Loyalty Perú S.A.C. y para todos
                  los efectos, la acreditación, la vigencia y el canje de puntos se rigen bajo los
                  términos y condiciones del programa, disponibles en 
                  <a href="https://www.bonus.pe/"></a>https://www.bonus.pe/.<br></br>
                  {/* En caso de devoluciones de compras que hayan originado la acreditación del bono de puntos, esto será descontado de la cuenta del Cliente. El programa de Puntos Bonus es desarrollado por Loyalty Perú S.A.C. y para todos los efectos, la acreditación, la vigencia y el canje de puntos se rigen bajo los términos y condiciones del programa, disponibles en https://www.bonus.pe/. */}
                </li>
                <li className="mb-2">
                  Descuento Primax: El beneficio será otorgado por el socio Coesti S.A. con la
                  intervención de Corporación Primax S.A. El usuario podrá acceder descuentos en los
                  Grifos Primax seleccionados, el cual aplicará dependiendo del tipo de combustible
                  y la región del país. El Usuario podrá suscribirse a este beneficio después de 24
                  horas de realizada su suscripción en el Programa Wong Prime en{' '}
                  <a href="https://primax.com.pe/convenios/" target="_blank" rel="noreferrer">
                    https://primax.com.pe/convenios/
                  </a>
                  . La acreditación, la vigencia y uso del beneficio se rige bajo los términos y
                  condiciones del programa, disponibles en{' '}
                  <a
                    href="https://primax.com.pe/wp-content/uploads/2022/09/Convenios-TyC-Beneficios-vf.pdf"
                    target="_blank"
                    rel="noreferrer">
                    https://primax.com.pe/wp-content/uploads/2022/09/Convenios-TyC-Beneficios-vf.pdf
                  </a>
                </li>
                <li className="mb-2">
                  Portal Prime: Canal de atención exclusiva otorgado por Cencosud para usuarios
                  Prime en donde se contará con una casilla de correo exclusiva para la atención de
                  sus operaciones.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Terms
